import { usePage } from '@inertiajs/vue3';

export function hasPermission(value) {
    const { can } = usePermissions();
    return can(value);
}

export function hasRole(value) {
    const { is } = usePermissions();
    return is(value);
}

export default function usePermissions() {
    const permissions = usePage().props.auth.user.permissions ?? [];
    const roles = usePage().props.auth.user.roles ?? [];

    const can = function (value) {
        let _return = false;
        if (roles.includes('superadmin')) {
            return true;
        }
        if (!Array.isArray(permissions)) {
            return false;
        }
        if (value.includes('|')) {
            value.split('|').forEach(function (item) {
                if (permissions.includes(item.trim())) {
                    _return = true;
                }
            });
        } else if (value.includes('&')) {
            _return = true;
            value.split('&').forEach(function (item) {
                if (!permissions.includes(item.trim())) {
                    _return = false;
                }
            });
        } else {
            _return = permissions.includes(value.trim());
        }
        return _return;
    };

    const is = function (value) {
        let _return = false;
        if (roles.includes('superadmin')) {
            return true;
        }
        if (!Array.isArray(roles)) {
            return false;
        }
        if (value.includes('|')) {
            value.split('|').forEach(function (item) {
                if (roles.includes(item.trim())) {
                    _return = true;
                }
            });
        } else if (value.includes('&')) {
            _return = true;
            value.split('&').forEach(function (item) {
                if (!roles.includes(item.trim())) {
                    _return = false;
                }
            });
        } else {
            _return = roles.includes(value.trim());
        }
        return _return;
    };

    return {
        roles: roles,
        permissions: permissions,
        can,
        is,
    };
}

<template>
    <button type="button" @click="show = true">
        <slot />
        <teleport v-if="show" to="#dropdown-teleport-target">
            <div>
                <div
                    style="
                        position: fixed;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: 99998;
                        background: black;
                        opacity: 0.2;
                    "
                    @click="show = false"
                />
                <div ref="dropdown" style="position: absolute; z-index: 99999" @click.stop="show = !autoClose">
                    <slot name="dropdown" />
                </div>
            </div>
        </teleport>
    </button>
</template>

<script lang="ts">
import type { Instance as PopperInstance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import type { Placement } from '@popperjs/core';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
    props: {
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-end',
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            popper: null as null | PopperInstance,
            show: false,
        };
    },
    watch: {
        show(show) {
            if (show) {
                this.$nextTick(() => {
                    this.popper = createPopper(this.$el, this.$refs.dropdown as HTMLDivElement, {
                        placement: this.placement,
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                options: {
                                    altBoundary: true,
                                },
                            },
                        ],
                    });
                });
            } else if (this.popper) {
                setTimeout(() => this.popper?.destroy(), 100);
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.show = false;
            }
        });
    },
});
</script>
